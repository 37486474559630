$font-dark: #123769;
$font-grey: #6a6f77;
$font-grey-1: #666b6d;
$font-blue: #5178e8;

$color-green: #4defa1;
$color-green-2: #81dfff;

$color-red: #ff3759;
$color-pink: #ff93f2;
$color-pink-1: #efb2f7;

$color-blue: #6ea5ef;
$color-blue-1: #88b6fe;

$color-grey: #cad1d4;
$color-grey-1: #aab0b8;
$color-grey-2: #e1e1ec;
$color-grey-3: #e3e3ed;

$color-purple-1: #ac9dfc;

:root {
    
    ////// PRUEBA /////
    --gradient-green: rgb(255, 102, 0);
    --gradient-green-rgb: rgba(255, 102, 0);
    --gradient-green-rgba: rgba(255, 102, 0, 1);
    --gradient-green-rgba-4: rgba(255, 102, 0, .4);
    --gradient-green-rgba-2: rgba(255, 102, 0, .2);
    --gradient-green-rgba-1: rgba(255, 102, 0, .1);

    --gradient-green-1: rgb(244, 159, 39);
    --gradient-green-1-rgba: rgb(244, 159, 39, 1);
    --gradient-green-1-rgba-4: rgb(244, 159, 39, .4);
    --gradient-green-1-rgba-2: rgb(244, 159, 39, .2);
    --gradient-green-1-rgba-1: rgb(244, 159, 39, .1);

    --gradient-blue: rgb(244, 159, 39);
    --gradient-blue-rgba: rgba(244, 159, 39, 1);
    --gradient-blue-rgba-1: rgba(244, 159, 39, .1);
    --gradient-blue-rgba-2: rgba(244, 159, 39, .2);
    --gradient-blue-rgba-4: rgba(244, 159, 39, .4);

    
    ////// PRUEBA /////
    // --gradient-green: #07889b;
    // --gradient-green-rgb: rgba(7, 136, 155);
    // --gradient-green-rgba: rgba(7, 136, 155, 1);
    // --gradient-green-rgba-4: rgba(7, 136, 155, .4);
    // --gradient-green-rgba-2: rgba(7, 136, 155, .2);
    // --gradient-green-rgba-1: rgba(7, 136, 155, .1);

    // --gradient-green-1: #36a0ad;
    // --gradient-green-1-rgba: rgb(54, 160, 173, 1);
    // --gradient-green-1-rgba-4: rgb(54, 160, 173, .4);
    // --gradient-green-1-rgba-2: rgb(54, 160, 173, .2);
    // --gradient-green-1-rgba-1: rgb(54, 160, 173, .1);

    // // #25CEBC
    // --gradient-blue: #66b9bf;
    // --gradient-blue-rgba: rgba(102, 185, 191, 1);
    // --gradient-blue-rgba-1: rgba(102, 185, 191, .1);
    // --gradient-blue-rgba-2: rgba(102, 185, 191, .2);
    // --gradient-blue-rgba-4: rgba(102, 185, 191, .4);

    ////// PRUEBA /////
    // --gradient-green: #f09819;
    // --gradient-green-rgb: rgba(240, 152, 25);
    // --gradient-green-rgba: rgba(240, 152, 25, 1);
    // --gradient-green-rgba-4: rgba(240, 152, 25, .4);
    // --gradient-green-rgba-2: rgba(240, 152, 25, .2);
    // --gradient-green-rgba-1: rgba(240, 152, 25, .1);

    // --gradient-green-1: rgb(244, 159, 39);
    // --gradient-green-1-rgba: rgb(244, 159, 39, 1);
    // --gradient-green-1-rgba-4: rgb(244, 159, 39, .4);
    // --gradient-green-1-rgba-2: rgb(244, 159, 39, .2);
    // --gradient-green-1-rgba-1: rgb(244, 159, 39, .1);

    // --gradient-blue: #f8a735;
    // --gradient-blue-rgba: rgba(248, 167, 53, 1);
    // --gradient-blue-rgba-1: rgba(248, 167, 53, .1);
    // --gradient-blue-rgba-2: rgba(248, 167, 53, .2);
    // --gradient-blue-rgba-4: rgba(248, 167, 53, .4);

    ////// PRUEBA /////
    // --gradient-green: rgb(155, 146, 192);
    // --gradient-green-rgb: rgba(155, 146, 192);
    // --gradient-green-rgba: rgba(155, 146, 192, 1);
    // --gradient-green-rgba-4: rgba(155, 146, 192, .4);
    // --gradient-green-rgba-2: rgba(155, 146, 192, .2);
    // --gradient-green-rgba-1: rgba(155, 146, 192, .1);

    // --gradient-green-1: rgb(108, 143, 153);
    // --gradient-green-1-rgba: rgb(108, 143, 153, 1);
    // --gradient-green-1-rgba-4: rgb(108, 143, 153, .4);
    // --gradient-green-1-rgba-2: rgb(108, 143, 153, .2);
    // --gradient-green-1-rgba-1: rgb(108, 143, 153, .1);

    // // #25CEBC
    // --gradient-blue: rgb(74, 216, 183);
    // --gradient-blue-rgba: rgba(74, 216, 183, 1);
    // --gradient-blue-rgba-1: rgba(74, 216, 183, .1);
    // --gradient-blue-rgba-2: rgba(74, 216, 183, .2);
    // --gradient-blue-rgba-4: rgba(74, 216, 183, .4);

    --blue: #0072ff;
    --blue-rgba-4: rgba(0,114,255,.4);
    --blue-rgba-2: rgba(0,114,255,.2);
    
    --gradient-pink: #ffb7f6;
    --gradient-pink-rgba: rgba(255,183,246,1);
    --gradient-pink-rgba-4: rgba(255,183,246,.4);

    --gradient-pink-1: #ff93f2;
    --gradient-pink-1-rgba: rgba(255,147,242, 1);
    --gradient-pink-1-rgba-4: rgba(255,147,242,.4);
    --gradient-pink-1-rgba-2: rgba(255,147,242,.2);

    --pink: #ff56ea;
    --pink-rgba-1: rgba(255, 86, 234, .1);

    --border-width: .3rem;
}

// :root {
    // --gradient-green: rgb(92, 197, 63);
//     --gradient-green-rgb: rgba(92, 197, 63);
//     --gradient-green-rgba: rgba(92, 197, 63, 1);
//     --gradient-green-rgba-4: rgba(92, 197, 63, .4);
//     --gradient-green-rgba-2: rgba(92, 197, 63, .2);
//     --gradient-green-rgba-1: rgba(92, 197, 63, .1);

//     --gradient-green-1: #17b491;
    // --gradient-green-1-rgba: rgb(23, 180, 145);
//     --gradient-green-1-rgba-4: rgba(23, 180, 145, .4);
//     --gradient-green-1-rgba-2: rgba(23, 180, 145, .2);
//     --gradient-green-1-rgba-1: rgba(23, 180, 145, .1);

//     --gradient-blue: #00b1e6;
//     --gradient-blue-rgba: rgba(0, 177, 230, 1);
//     --gradient-blue-rgba-1: rgba(0, 177, 230, .1);
//     --gradient-blue-rgba-2: rgba(0, 177, 230, .2);
//     --gradient-blue-rgba-4: rgba(0, 177, 230, .4);

//     --blue: #0072ff;
//     --blue-rgba-4: rgba(0,114,255,.4);
//     --blue-rgba-2: rgba(0,114,255,.2);
    
//     --gradient-pink: #ffb7f6;
//     --gradient-pink-rgba: rgba(255,183,246,1);
//     --gradient-pink-rgba-4: rgba(255,183,246,.4);

//     --gradient-pink-1: #ff93f2;
//     --gradient-pink-1-rgba: rgba(255,147,242, 1);
//     --gradient-pink-1-rgba-4: rgba(255,147,242,.4);
//     --gradient-pink-1-rgba-2: rgba(255,147,242,.2);

//     --pink: #ff56ea;
//     --pink-rgba-1: rgba(255, 86, 234, .1);

//     --border-width: .3rem;
// }